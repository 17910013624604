
import { StateType } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StateTypeSpan extends Vue {
  @Prop({ required: true }) state!: StateType;

  get stateType(){
    switch (this.state){
      case StateType.PLANNED: {
        return  this.$t('created')
      }
       case StateType.RESUMED: {
        return  this.$t('resumed')
      }
      case StateType.STARTED: {
        return this.$t('started')
      }
      case StateType.COMPLETED: {
        return this.$t('completed')
      }
      case StateType.SUSPENDED: {
        return this.$t('suspended')
      }
      case StateType.ARCHIVED: {
        return this.$t('archived')
      }
    }
    return this.$t('unknownStatus')
  } 
} 
